export default {
    API_ATENDENTE: 'https://api.sistefood.com.br:443/site/',
    API_ATENDENTE_MESA_ATENDENTE: 'https://api.sistefood.com.br:443/app/',
    API_PRODUTO: 'https://api.sistefood.com.br:443/site/',
    API_ACOMPANHAMENTO: 'https://api.sistefood.com.br:443/sistefood/',
    API_PARAMETRO:'https://api.sistefood.com.br:443/sistefood/',
    API_USUARIO:'https://api.sistefood.com.br:443/site/',
    API_QRCODE:'https://api.sistefood.com.br:443/site/',
    API_MESA:'https://api.sistefood.com.br:443/app/',
    API_PRODUTO_LOTE: 'https://api.sistefood.com.br:443/app/',
    API_PAGAMENTO: 'https://api.sistefood.com.br:443/app/',
    API_PAGAMENTO_DELIVERY: 'https://api.sistefood.com.br:443/sistefood/',
    API_DASHBOARD: 'https://api.sistefood.com.br:443/sistefood/',
    API_COUVERT:'https://api.sistefood.com.br:443/app/',
    API_TAXES:'https://api.sistefood.com.br:443/app/',
    API_DELIVERY:'https://api.sistefood.com.br:443/sistefood/',
    API_PERSON:'https://api.sistefood.com.br:443/sistefood/',
    API_DELIVERY_MAN:'https://api.sistefood.com.br:443/sistefood/',
    API_TABLE_COUNT:'https://api.sistefood.com.br:443/app/',
    API_DELIVERY_COUNT:'https://api.sistefood.com.br:443/sistefood/',
    API_COZINHA:'https://api.sistefood.com.br:443/sistefood/',
    API_PRINT:'https://api.sistefood.com.br:443/sistefood/',
    API_FREIGHT:'https://api.sistefood.com.br:443/sistefood/',
    API_PRODUCT_COMPLEMENT:'https://api.sistefood.com.br:443/cardapio/',
    API_CONSULT_SALE:'https://api.sistefood.com.br:443/sistefood/',
    API_FORM_OF_PAYMENT:'https://api.sistefood.com.br:443/sistefood/',
    API_EXPENSE:'https://api.sistefood.com.br:443/sistefood/',
    API_REVENUE:'https://api.sistefood.com.br:443/sistefood/',
    API_CURRENT_ACCOUNT:'https://api.sistefood.com.br:443/sistefood/',
    API_BILLS_TO_PAY:'https://api.sistefood.com.br:443/sistefood/',
    API_BILLS_TO_RECEIVE:'https://api.sistefood.com.br:443/sistefood/',
    API_NOTE_ENTRY:'https://api.sistefood.com.br:443/sistefood/',
    API_NOTE_SINGLE:'https://api.sistefood.com.br:443/site/',
    API_INVENTORY:'https://api.sistefood.com.br:443/site/',
    API_MANUFACTOR:'https://api.sistefood.com.br:443/sistefood/',
    API_SECTION:'https://api.sistefood.com.br:443/sistefood/',
    API_CATEGORY:'https://api.sistefood.com.br:443/sistefood/',
    API_EMAIL:'https://api.sistefood.com.br:443/sistefood/',
    API_NCM:'http://api.sistefood.com.br:443/sistefood/',
    VIACEP: 'https://viacep.com.br/ws/',

    
   //Servidor Comum
    // API_ATENDENTE: 'http://192.168.1.74:9000/site/',
    // API_ATENDENTE_MESA_ATENDENTE: 'http://192.168.1.74:9000/app/',
    // API_PRODUTO: 'http://192.168.1.74:9000/site/',
    // API_ACOMPANHAMENTO: 'http://192.168.1.74:9000/sistefood/',
    // API_PARAMETRO:'http://192.168.1.74:9000/sistefood/',
    // API_USUARIO:'http://192.168.1.74:9000/site/',
    // API_QRCODE:'http://192.168.1.74:9000/site/',
    // API_MESA:'http://192.168.1.74:9000/app/',
    // API_PRODUTO_LOTE: 'http://192.168.1.74:9000/app/',
    // API_PAGAMENTO: 'http://192.168.1.74:9000/app/',
    // API_PAGAMENTO_DELIVERY: 'http://192.168.1.74:9000/sistefood/',
    // API_DASHBOARD: 'http://192.168.1.74:9000/sistefood/',
    // API_COUVERT:'http://192.168.1.74:9000/app/',
    // API_TAXES:'http://192.168.1.74:9000/app/',
    // API_DELIVERY:'http://192.168.1.74:9000/sistefood/',
    // API_PERSON:'http://192.168.1.74:9000/sistefood/',
    // API_DELIVERY_MAN:'http://192.168.1.74:9000/sistefood/',
    // API_TABLE_COUNT:'http://192.168.1.74:9000/app/',
    // API_DELIVERY_COUNT:'http://192.168.1.74:9000/sistefood/',
    // API_COZINHA:'http://192.168.1.74:9000/sistefood/',
    // API_PRINT:'http://192.168.1.74:9000/sistefood/',
    // API_FREIGHT:'http://192.168.1.74:9000/sistefood/',
    // API_PRODUCT_COMPLEMENT:'http://192.168.1.74:9000/cardapio/',
    // API_CONSULT_SALE:'http://192.168.1.74:9000/sistefood/',
    // VIACEP: 'https://viacep.com.br/ws/',

    //Servidor Local 
    // API_ATENDENTE: 'http://192.168.1.43:9000/site/',
    // API_ATENDENTE_MESA_ATENDENTE: 'http://192.168.1.43:9000/app/',
    // API_PRODUTO: 'http://192.168.1.43:9000/site/',
    // API_ACOMPANHAMENTO: 'http://192.168.1.43:9000/sistefood/',
    // API_PARAMETRO:'http://192.168.1.43:9000/sistefood/',
    // API_USUARIO:'http://192.168.1.43:9000/site/',
    // API_QRCODE:'http://192.168.1.43:9000/site/',
    // API_MESA:'http://192.168.1.43:9000/app/',
    // API_PRODUTO_LOTE: 'http://192.168.1.43:9000/app/',
    // API_PAGAMENTO: 'http://192.168.1.43:9000/app/',
    // API_PAGAMENTO_DELIVERY: 'http://192.168.1.43:9000/sistefood/',
    // API_DASHBOARD: 'http://192.168.1.43:9000/sistefood/',
    // API_COUVERT:'http://192.168.1.43:9000/app/',
    // API_TAXES:'http://192.168.1.43:9000/app/',
    // API_DELIVERY:'http://192.168.1.43:9000/sistefood/',
    // API_PERSON:'http://192.168.1.43:9000/sistefood/',
    // API_DELIVERY_MAN:'http://192.168.1.43:9000/sistefood/',
    // API_TABLE_COUNT:'http://192.168.1.43:9000/app/',
    // API_DELIVERY_COUNT:'http://192.168.1.43:9000/sistefood/',
    // API_COZINHA:'http://192.168.1.43:9000/sistefood/',
    // API_PRINT:'http://192.168.1.43:9000/sistefood/',
    // API_FREIGHT:'http://192.168.1.43:9000/sistefood/',
    // API_PRODUCT_COMPLEMENT:'http://192.168.1.43:9000/cardapio/',
    // API_CONSULT_SALE:'http://192.168.1.43:9000/sistefood/',
    // API_FORM_OF_PAYMENT:'http://192.168.1.43:9000/sistefood/',
    // API_EXPENSE:'http://192.168.1.43:9000/sistefood/',
    // API_REVENUE:'http://192.168.1.43:9000/sistefood/',
    // API_CURRENT_ACCOUNT:'http://192.168.1.43:9000/sistefood/',
    // API_BILLS_TO_PAY:'http://192.168.1.43:9000/sistefood/',
    // API_BILLS_TO_RECEIVE:'http://192.168.1.43:9000/sistefood/',
    // API_NOTE_ENTRY:'http://192.168.1.43:9000/sistefood/',
    // API_NOTE_SINGLE:'http://192.168.1.43:9000/site/',
    // API_INVENTORY:'http://192.168.1.43:9000/site/',
    // API_MANUFACTOR:'http://192.168.1.43:9000/sistefood/',
    // API_CFOP:'http://192.168.1.43:9000/sistefood/',
    // API_SECTION:'http://192.168.1.43:9000/sistefood/',
    // API_CATEGORY:'http://192.168.1.43:9000/sistefood/',
    // API_EMAIL:'http://192.168.1.43:9000/sistefood/',
    // API_NCM:'http://192.168.1.43:9000/sistefood/',
    // VIACEP: 'https://viacep.com.br/ws/',

    // API_ATENDENTE: 'http://localhost:9000/site/',
    // API_ATENDENTE_MESA_ATENDENTE: 'http://localhost:9000/app/',
    // API_PRODUTO: 'http://localhost:9000/site/',
    // API_ACOMPANHAMENTO: 'http://localhost:9000/sistefood/',
    // API_PARAMETRO:'http://localhost:9000/sistefood/',
    // API_USUARIO:'http://localhost:9000/site/',
    // API_QRCODE:'http://localhost:9000/site/',
    // API_MESA:'http://localhost:9000/app/',
    // API_PRODUTO_LOTE: 'http://localhost:9000/app/',
    // API_PAGAMENTO: 'http://localhost:9000/app/',
    // API_PAGAMENTO_DELIVERY: 'http://localhost:9000/sistefood/',
    // API_DASHBOARD: 'http://localhost:9000/sistefood/',
    // API_COUVERT:'http://localhost:9000/app/',
    // API_TAXES:'http://localhost:9000/app/',
    // API_DELIVERY:'http://localhost:9000/sistefood/',
    // API_PERSON:'http://localhost:9000/sistefood/',
    // API_DELIVERY_MAN:'http://localhost:9000/sistefood/',
    // API_TABLE_COUNT:'http://localhost:9000/app/',
    // API_DELIVERY_COUNT:'http://localhost:9000/sistefood/',
    // API_COZINHA:'http://localhost:9000/sistefood/',
    // API_PRINT:'http://localhost:9000/sistefood/',
    // API_FREIGHT:'http://localhost:9000/sistefood/',
    // API_PRODUCT_COMPLEMENT:'http://localhost:9000/cardapio/',
    // API_CONSULT_SALE:'http://localhost:9000/sistefood/',
    // API_FORM_OF_PAYMENT:'http://localhost:9000/sistefood/',
    // API_EXPENSE:'http://localhost:9000/sistefood/',
    // API_REVENUE:'http://localhost:9000/sistefood/',
    // API_CURRENT_ACCOUNT:'http://localhost:9000/sistefood/',
    // API_BILLS_TO_PAY:'http://localhost:9000/sistefood/',
    // API_BILLS_TO_RECEIVE:'http://localhost:9000/sistefood/',
    // API_NOTE_ENTRY:'http://localhost:9000/sistefood/',
    // API_NOTE_SINGLE:'http://localhost:9000/site/',
    // API_INVENTORY:'http://localhost:9000/site/',
    // API_MANUFACTOR:'http://localhost:9000/sistefood/',
    // API_CFOP:'http://localhost:9000/sistefood/',
    // API_SECTION:'http://localhost:9000/sistefood/',
    // API_CATEGORY:'http://localhost:9000/sistefood/',
    // API_EMAIL:'http://localhost:9000/sistefood/',
    // API_NCM:'http://localhost:9000/sistefood/',
    // VIACEP: 'https://viacep.com.br/ws/',

    // Servidor Comum hamachi
    // API_ATENDENTE: 'http://25.53.124.126:9000/site/',
    // API_ATENDENTE_MESA_ATENDENTE: 'http://25.53.124.126:9000/app/',
    // API_PRODUTO: 'http://25.53.124.126:9000/site/',
    // API_ACOMPANHAMENTO: 'http://25.53.124.126:9000/sistefood/',
    // API_PARAMETRO:'http://25.53.124.126:9000/sistefood/',
    // API_USUARIO:'http://25.53.124.126:9000/site/',
    // API_QRCODE:'http://25.53.124.126:9000/site/',
    // API_MESA:'http://25.53.124.126:9000/app/',
    // API_PRODUTO_LOTE: 'http://25.53.124.126:9000/app/',
    // API_PAGAMENTO: 'http://25.53.124.126:9000/app/',
    // API_PAGAMENTO_DELIVERY: 'http://25.53.124.126:9000/sistefood/',
    // API_DASHBOARD: 'http://25.53.124.126:9000/sistefood/',
    // API_COUVERT:'http://25.53.124.126:9000/app/',
    // API_TAXES:'http://25.53.124.126:9000/app/',
    // API_DELIVERY:'http://25.53.124.126:9000/sistefood/',
    // API_PERSON:'http://25.53.124.126:9000/sistefood/',
    // API_DELIVERY_MAN:'http://25.53.124.126:9000/sistefood/',
    // API_TABLE_COUNT:'http://25.53.124.126:9000/app/',
    // API_DELIVERY_COUNT:'http://25.53.124.126:9000/sistefood/',
    // API_COZINHA:'http://25.53.124.126:9000/sistefood/',
    // API_PRINT:'http://25.53.124.126:9000/sistefood/',
    // API_FREIGHT:'http://25.53.124.126:9000/sistefood/',
    // API_PRODUCT_COMPLEMENT:'http://25.53.124.126:9000/cardapio/',
    // API_CONSULT_SALE:'http://25.53.124.126:9000/sistefood/',
    // VIACEP: 'https://viacep.com.br/ws/',
       

}   

