import styled from "styled-components";

export const BoxCadEmpresa = styled.div`
    position: fixed;
    display: flex;
    z-index: 101;
    height: 100vh;
    width: 100vw;
    left: 0;
    top:0;
    flex-direction: flex ;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:#C2C9D6;
    background-color: ${props => props.theme.backgrounds.gray6};
`;


export const BoxModal = styled.div`

    width: 500px;
    height: 350px;
    background-color:  #FAFAFA;
    margin: 0;
    border-radius:${props => props.theme.border_radius.medium} ;
    padding: 0;
    overflow: hidden;

    thead{
        position: sticky;
        top:0;
        background-color: white;
    }


    a{
        height: 35px;
    }

    #Header{
        margin: 0;
        padding: 0;
        justify-content: space-between;
        color:${props => props.theme.colors.black};
        font-size: 20px;
        align-items: center;
        padding-left: ${props => props.theme.paddings.small};
        border-bottom:solid 2px #DDE2EC;
        color:#3B4758;
    }

    .row .col ul{
        padding: 15px;
        display: flex;
        flex-direction: flex ;
        width: 100%;
        height: 100%;
        margin:0;
        padding:0;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        text-align: center;
        list-style:none; 
        overflow-y: auto;
        padding-top: 25px;
    }

    .row .col ul li > a{
        text-decoration: none;
        color:#3B4758;
    }

    .Close{

        z-index: 1000;
        background: transparent;
        right:calc(-100% + 64px);
        border:none;
        outline: none;
        color:${props => props.theme.colors.black};
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;

        &::before,
        &::after {
            content: ' ';
            position: absolute;
            width: 2.5px;
            height: 24px;
            background-color: #000;
            
        }

        &::before{
            transform: rotate(45deg);
        }

        &::after{
            transform: rotate(-45deg);
        }
    }

    .row{
        margin: 0;
    }

    .overflow{
        overflow-y: auto;  
        height: 300px;
    };
`;