import { useEffect, useState } from "react";
import { getToken } from "../services/auth";
import CountDeliveryAndTable from "../services/countDeliveryAndTable";

export const useCountTable = ({socket}) => {
    const [countTable, setCountTable] = useState(0);

    useEffect(()=> {
        if(window.location.pathname !== '/'){
            verifyUrl();
            socket.on('countTable', (data) => {
                setCountTable(data.data.count)
            })
        }

        return () => socket.off('countTable')
    }, []);

    const getCountTable = async () => {
        try {
            const cnpj = getToken('CNPJ');
            const service = new CountDeliveryAndTable();
            const result = await service.getCountTable({
                cnpj
            })
            
            if (result.success) {
                socket.emit('countTable', {
                    id: getToken('CNPJ'),
                    socketID: socket.id,
                    count: result.data[0].qtd
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const verifyUrl = () => { if (countTable === 0) getCountTable();}


    return {countTable, setCountTable, getCountTable}
}