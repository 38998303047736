import * as Style from './styles';

export const ListItemButton = ({children, ...rest}) => {
    // const {nameTooltip} = rest;
    // const [styles, setStyle] = useState({
    //     position: 'absolute',
    //     top:0
    // })
    // const positionCurrent = (event) => {
    //     const value= event.target;
        
    //     let {top, y} = value.getBoundingClientRect();
    //     if(top === styles.top) return 
    //     setStyle(state => {return{...state, top, y}})

    // }

    // console.log(styles)
    return(
        // <Style.Teste styles={styles}  onMouseEnter={(e) => positionCurrent(e)}>
            <Style.Li {...rest} >
                {children}
            </Style.Li>    
                // <Style.TooltipBox  >
                //     {nameTooltip}
                // </Style.TooltipBox>
        // </Style.Teste>
    )
}