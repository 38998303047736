import React from "react";
import {Link, withRouter} from "react-router-dom";
import {Row, Col, Table} from "react-bootstrap";
import {BoxCadEmpresa, BoxModal} from './styles'
import  {entrar} from '../../../services/auth'
import { contextClass } from "../../../HOC/useContextClass";

class CadEmpresas extends React.Component {

  constructor(props){
      super(props)
      this.state ={
        id: 'modal',
        cliqueHref: '',
        cliqueHref2: '',
        CadEmpresa:[],
        isLoading:true
      }

      this.RenderTable = this.RenderTable.bind(this)
  }

  componentDidMount() {
    this.setState({
      CadEmpresa: this.props.resultado,
      isLoading:false
    })

  }

  HandleCliqueClose = (e) => {if (e.target.id === this.state.id) this.props.onClose()}

  HandleCliqueHref = (item, index) => {
    try {
      const {CadEmpresa} = this.state;
      const {location} = this.props.history;
      if(location.pathname !== '/') window.location.reload();
      this.setState({cliqueHref: item.valor})
      this.setState({cliqueHref2: item.nome_empresa})
      entrar('CNPJ', item.valor); 
      entrar('NOMEEMPRESA',item.nome_empresa); 
      entrar('SISTESHOP', item.sisteshop === 'S' ?  true : false);
      entrar('ADM', CadEmpresa.usuario[index].administrador);   
      entrar('COMPANIES', JSON.stringify(CadEmpresa));   
      this.props.login(CadEmpresa.usuario[index].pemissao_acesso);

    } catch (error) {
      console.log(error)
    }
  }
  
  RenderTable ({CadEmpresa})  {
    return(
        <Table hover className={'headerFixed'}>
          <thead style={{color:'#3B4758'}}>
            <tr>
              <th>Empresa</th>
              <th>CNPJ</th>
            </tr>
            
          </thead>

          <tbody>
            {
              Object.keys(CadEmpresa).reduce((ele)=> {
                return (
                  CadEmpresa[ele].map((item, index)=>
                    <tr key={item.nome_empresa}>
                      <td style={{textAlign:'left',paddingLeft:'40px'}}>{item.nome_empresa}</td>
                      <td>
                        <Link  className='btn btn-primary' style={{width:'146.75px'}}  onClick={() => this.HandleCliqueHref(item, index)} to={`${this.props.history.location.pathname !=='/' ? this.props.history.location.pathname : 'dashboard'}`}> Entrar
                        </Link>
                      </td>
                    </tr>  
                  )

                )

              })
            }
          </tbody>
        </Table>
    )

  }

  render(){
    const {CadEmpresa, isLoading} = this.state
    return(
      <BoxCadEmpresa id={this.state.id} className='teste' onClick={(e) => this.HandleCliqueClose(e)}>
        <BoxModal >
          <Row id='Header'>
            Empresas Cadastradas
            <button  className="Close" onClick={()=> this.props.onClose()}></button>
          </Row>

          <Row >
            <Col className="overflow">
              {!isLoading &&  <this.RenderTable CadEmpresa={CadEmpresa}/>}
            </Col>
          </Row>
          
        </BoxModal>
         
      </BoxCadEmpresa>
    )
  }  

}

const cadEmpresas1 = contextClass(CadEmpresas);

export default withRouter(cadEmpresas1);