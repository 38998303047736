
const createStore = (reducer) => {
    let statedaStore;
    const subscribers = [];
    const subscribe = (funcao, action) => {   
        // deleteSubscribe()
        const result = subscribers.filter(x => Object.keys(x) == action)
        if(result.length == 0 ){
            subscribers.push({[action]:funcao});
            
        }
    }
    

    const dispatch = (action) => {
        statedaStore = reducer(statedaStore, action);
        subscribers.forEach((funcao) => {

           const chave = Object.keys(funcao);
           funcao[chave]();

        });
    }
    // const deleteSubscribe = ()=> subscribers.splice(0)

    const deleteSubscribe = (action)=> {

        subscribers.forEach((funcao, index) => {
            const chave = Object.keys(funcao);
            if(chave.toString() === action) subscribers.splice(index, 1);
         });
    }

    return {
        getState: () => statedaStore,
        subscribe,
        dispatch,
        deleteSubscribe
    }
}




const deliveryReducer = (state = [], action = {} ) => {
    if(action.type === 'LOADING_DELIVERY') {

        return [...action.listInProgressOrder];
    }

    if(action.type === 'REMOVE_DELIVERY') {
        return [...action.listInProgressOrder];
    }
    // if(action.type === 'NEW_DELIVERY'){
    //     return [action.listInProgressOrder]
    // }

    return {state, action};

}


export const store = createStore(deliveryReducer);