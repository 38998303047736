export const theme = {
    display:{
        flex:'flex',
        block:'block',
        inline:'inline'
    },
    flex_direction:{
        column:'column',
    },
    align_items:{
        flex_start: 'flex-start',
        center: 'center',
        flex_end: 'flex-end',
    },
    justify_content:{
        center: 'center',
        flex_end: 'flex-end',
        flex_start: 'flex-start',
        space_between:'space-between'
    },
    width:{
        _100_percent:'100%',
        max_width_560: '560px',
        _288: '288px',
        _132: '132px',
        _51_31: '51.31px'
    },
    height:{
        _100_percent:'100%',
        _98:'98px',
        _42:'42px',
        _55:'55px',
        _48:'48px',
        _84:'84px',
    },
    font_size:{
        very_small: '12px',
        small: '14px',
        medium: '16px',
        large: '18px',
    },
    font_style:{
        normal:'normal',
    },
    font_weight:{
        medium:'400',
        more_less_big:'500',
        large:'600',
    },
    lines_height:{
        small:'15px',
        medium:'18px',
        large:'20px',
    },
    colors:{
        white:'#fff',
        black:'rgb(47, 47, 47)',
        red: 'rgb(197, 31, 42)',
        red2:'#C51F2A',
        red3:'#DEDEDE',
        gray:'#2F2F2F',
        gray2:'#999999',
        gray3:'#717171',
        gray4:'#BFBFBF',
        gray5:'#DCDCDC',
        gray6:'rgba(0, 0, 0, 0.3)',
        gray7:'#F2F2F2',
        blue:'#0B02C1',
        blue2:'#09046F',
        blue3:'#DBE2FC',
        blue4:'#03134F'
    },
    backgrounds:{
        white:'#fff',
        black:'rgb(47, 47, 47)',
        gray:'#2F2F2F',
        gray2:'#999999',
        gray3:'#717171',
        gray4:'#BFBFBF',
        gray5:'#DCDCDC',
        gray6:'rgba(0, 0, 0, 0.3)',
        gray7:'#F2F2F2',
        red: 'rgb(197, 31, 42)',
        red2:'#C51F2A',
        red3:'#DEDEDE',
        blue:'#0B02C1',
        blue2:'#09046F',
        blue3:'#DBE2FC',
        blue4:'#03134F'
    },
    gaps:{
        very_small:'8px',
        small:'10px',
        medium: '12px',
        more_less_big: '16px',
        large:'24px',
    },
    paddings:{
        small:'10px',
        medium: '12px',
        more_less_big:'16px',
        medium_16_24: '16px 24px',
        _12_24: '12px 24px',
        large:'24px',
    },
    border_radius:{
        small: '8px',
        medium:'10px',
        more_less_big:'16px',
        large:'24px'
    },
    border:{
        very_small: 'solid 1px #DEDEDE',
        very_small_gray5: '1px solid #DCDCDC',
        very_small_red2: '1px solid #C51F2A',
        very_small_blue:'1px solid #0B02C1',
        small: '1.5px solid #DCDCDC',
        small_red2: '1.5px solid #C51F2A',
        small_blue: '1.5px solid #0B02C1',
        small_gray4: '1.5px solid #BFBFBF',
    },
    cursors:{
        pointer:'pointer',
    },
    margins:{
        small:'16px',
        medium:'24px',
        large:'56px',
        very_large:'84px'
    }



}