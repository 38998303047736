
import * as Styles from './styles';

export const ButtonAction = ({children, ...rest}) => {
    return(
        <Styles.RedirectButton 
            {...rest}
        >
            {children}
        </Styles.RedirectButton>

    )
}