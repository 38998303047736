import { List } from "./List";
import { ListItem } from "./ListItem";
import { ListItemButton } from "./ListItemButton";
import { ListItemIcon } from "./ListItemIcon";
import { ListItemText } from "./ListItemText";
import { ListItemTitle } from "./ListItemTitle";

export const Navigation = {
    Root: List,
    ListItem,
    ListItemIcon,
    ListItemTitle,
    ListItemText,
    ListItemButton
}