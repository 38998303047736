import { entrar, getToken } from "../services/auth";
import { cpf, cnpj } from 'cpf-cnpj-validator';

export function FormatCurrency  (value)  {
    let valor = value;
    let valorFormatado = valor.toLocaleString('pt-BR', { style: 'currency', currency:'BRL'});
    return valorFormatado
}

export const formatCompare = (value) => {

    let floatValue = value.toString();
    floatValue = floatValue.replace(/\D/g, '');
    let money =   parseFloat(floatValue)/100; 
    return money
}

export const  removeCifrao = (value) => {
    let result = value.toString();
    result = result.replace('R$ ', '');
    return result
}

export const MaskCurrency = (value)=>{
    let valor  = value;
    valor = value.replace(/\D/g,'');
    valor = (valor/100).toFixed(2) + '';
    valor = valor.replace(".", ",");
    valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    valor = valor;
    
    if(valor === 'NaN' ){
        return '0,00'
    }

    return valor

}

export const VerifyWidth = () => {
    if(window.innerWidth <= 1550){
        return true;
    }
    else {
        return false;
    }
}

let mousedown = '';
let mouseup   = '';

export const verifyMouse = (event, functions, callback) => {
    if(event.type === 'mousedown') mousedown = event.target.id;

    if(event.type === 'mouseup'){
        mouseup = event.target.id;
        if (mousedown === mouseup) functions(event, callback);
    }

}  

export const updatePhone = (value) => {
    let valor = value.toString();
    valor = parseInt(valor.replace(/[\D]+/g, ''));

    if( isNaN(valor) ) {
        return ''
    }


    return  maskPhone(valor);
}

export const maskPhone = (value) => {
    let valor = value.toString();
    if (valor) {

        valor = valor.replace(/\D/g,'')
        valor = valor.replace(/(\d{2})(\d)/,"($1) $2")
        valor = valor.replace(/(\d)(\d{4})$/,"$1-$2")
        
        return valor
    }  
}

export const removeMaskPhone = (telefone) => {
    let value = telefone;
    if (value.length <= 15){
        value.toString();
        value = value
            .replace(')', '')
            .replace('(', '')
            .replace('-', '')
            .replace(' ', '')
        return value
    }
    else{
        return ''   
    }
}

export const validatePhone = (value) => {
    let valor = value.toString();
    var regex = /^\(\d{2}\) \d{4,5}-\d{4}$/gi;
    return regex.test(valor)
}

export const ValidarEmail = (e) => {
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    return validEmail.test(e)
}

export const updateEmail = (e) => {
    return e;
}

export const VerificarCpfCnpj = (valor) => {
    let validar = valor;
    if (11 === validar.length  ) {
        return cpf.isValid(validar)
    }
    else {
        return cnpj.isValid(validar)  
    }

}

export const verifyIsNumber = (value) => {
    let valor = value.toString();
    valor = valor.replace(/[^0-9]/g,'');

    return valor
}

export const complement = {
    items:[]
}

export const removeSpecials = (texto) => {
    texto = texto.replace(/[ÀÁÂÃÄÅ]/,"A");
    texto = texto.replace(/[àáâãäå]/,"a");
    texto = texto.replace(/[ÈÉÊË]/,"E");
    texto = texto.replace(/[èéêë]/,"E");
    texto = texto.replace(/[Ç]/,"C");
    texto = texto.replace(/[ç]/,"c");
    return texto.replace(/[^a-z0-9]/gi,' '); 
}

export const isNotVisible = () => {
    const element = document.getElementById('box-lateral-responsive');
    if(element === null) return
    element.classList.remove('modal');
    element.classList.add('isNotModal');
}

export const isVisibleBoxLateral = () => {
    const element = document.getElementById('box-lateral-responsive');
    element.classList.remove('isNotModal');
    element.classList.add('modal');
}

export const messageModal = {
    textBody: ''
}

export const calculatePagination = (start = 1) => {

    const toStart = start > 1 ? ((50 * start) - 50) : 0 ;
    const end = start > 1 ?  (50 * start) : 50;

    return {
        start:toStart, end
    }
}

export const handleClickCloseModal = (event, callback) =>{
    const result = VerifyWidth();
    if(event.target.id === 'box-lateral-responsive' && result){
        callback();
    }
}

export const handleClickCloseModalGeneric = (event, callback) =>{
    if(event.target.id === 'modal-generic'){
        callback();
    }
}

export const formatedDateBR = (date) => {
    const dataComIntl = new Intl.DateTimeFormat('pt-BR', {
        dateStyle: 'short',
    });

    return dataComIntl.format(date);
}

export const formatDateToEUA = (value) => {
    return `${value.substr(6,4)}-${value.substr(3,2)}-${value.substr(0,2)}`
}

export const sortArrayObject = (data, dir = 'ASC', key = null, type='') => {
    let firstItem = key ? data[0][key] : data[0];
    if(!firstItem && (type === 'number' || type === 'integer')) firstItem = 0
    if(!firstItem && type === 'date') firstItem = '31/12/1969'
    if(!firstItem && type === 'string') firstItem = ''

    const isNumber =  type !== 'string' ? !isNaN(formatCompare(firstItem)) : false;
    const isAsc = dir.toUpperCase() === 'ASC';

    if (type === 'date'){
        return data.sort((a, b) => {
                let x = key ? a[key] : a;
                let y = key ? b[key] : b;
                x = x.split('/');
                y = y.split('/');
                x = new Date(x[2], x[1] - 1, x[0]);
                y = new Date(y[2], y[1] - 1, y[0]);
                if(isAsc) return x - y;
                if(!isAsc) return y - x;   
                
            })
    }

    if(isNumber && (type === 'number' || type === 'integer')){
        return data.sort((a, b) => {
            let x = key ? a[key] : a;
            let y = key ? b[key] : b;
            if(isAsc) return x - y;
            if(!isAsc) return y - x;
        })
    }

    return data.sort((a, b) => {
        let x = key ? a[key] : a;
        let y = key ? b[key] : b;
        if(isAsc) {
            if(x === null) {
                x = '';
                return x.localeCompare(String(y ?? ''))
            }
            return x.localeCompare(String(y))
        };
        if(!isAsc) {
            if(y === null ) {
                y = '';
                return y.localeCompare(String(x ?? ''))
            }
            return y.localeCompare(String(x ?? ''))
        };
    })
}

export const saveGrid = (item, nameGrid) => {
    let items = item
    const userId = JSON.parse(getToken('ID_USUARIO'));
    const grid = JSON.parse(getToken('GRID'));

    if(grid === null || grid === undefined) {

        items = item[0].columns.map(item => {
            if(item.props !== undefined)  delete item.props
            return item 
        })

        entrar('GRID', JSON.stringify(
            { 
                [nameGrid]: [{ [userId] :item[0].columns }]
              
            }))
    }
    else {

        items = item[0].columns.map(item => {
            if(item.props !== undefined)  delete item.props
            return item 
        })


        if(grid[nameGrid] === undefined){
            grid[nameGrid] = [{ [userId]: item[0].columns }]
            return entrar('GRID', JSON.stringify(grid))
        }
        if (grid[nameGrid][0][userId] !== undefined){
            items = item[0].columns.map(item => {
                if(item.props !== undefined)  delete item.props
                return item 
            })
            grid[nameGrid][0][userId] = userId
            grid[nameGrid][0][userId] = item[0].columns;
            entrar('GRID', JSON.stringify(grid))
        }
        else {
            grid[nameGrid][0][userId] = userId
            grid[nameGrid][0][userId] = item[0].columns;
            entrar('GRID', JSON.stringify(grid))
        }
    }

}

export const loadingGrid = (nameGrid) => {
    let verifyGrid = JSON.parse(getToken('GRID'));
    const userId  =  JSON.parse(getToken('ID_USUARIO'));    
    const validationGrid  = verifyGrid === null || verifyGrid === undefined ? false : true;
    if(validationGrid && verifyGrid[nameGrid] === undefined) return []
    if (validationGrid && verifyGrid[nameGrid][0][userId] !== undefined) return verifyGrid[nameGrid][0][userId];
    return [];
}

export const addClassAccount = (item) => {
        const {dt_vencimento} = item;
        const data= new Date();
        const year = data.getFullYear()
        const month = (data.getMonth()+ 1)
        const day = data.getDate()
        let dateFormated =  `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
        let parts =  dt_vencimento.split('/');
        let dateValid = new Date(parts[2], parts[1] - 1, parts[0]);
        dateFormated = dateFormated.split('/');
        dateFormated = new Date(dateFormated[2], dateFormated[1] -1 , dateFormated[0]);
        if (dateFormated < dateValid) return 'TableGreen';
        else if (dateFormated > dateValid) return 'TableRed';
        return 'TableBlue';
}

let dataObjectPrint = ''

export const setDataObjectPrint = (value) => (
    dataObjectPrint = value
)

export const getDataObjectPrint = () => dataObjectPrint

export let validationSenEmail = JSON.parse(getToken('SENDEMAIL')) ?? false;
export let email = '';

export const setEmail = (value) => email = value;
export const getEmail = () =>  email;

export const setvalidationSenEmail = () => validationSenEmail = !validationSenEmail;


export default dataObjectPrint