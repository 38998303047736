import React from "react";
import iconInfo from "../../assets/TopBar/iconInfo.svg";
import iconUser from "../../assets/TopBar/iconUser.svg";
import IconLogo from "../../assets/TopBar/IconLogo.svg";
import IconLogoBlue from "../../assets/TopBar/IconLogoBlue.svg";
import { getToken } from "../../services/auth";
import * as S from "./styles";
import * as Style from "../../shared/GlobalStyles/styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import IconPerson from "../../assets/menuLateral/IconPerson.svg";
import iconAttendant from "../../assets/menuLateral/iconAttendant.svg";
import IconNoteSingle from "../../assets/menuLateral/IconNoteSingle.svg";
import IconInventory from "../../assets/menuLateral/IconInventory.svg";
import iconItems from "../../assets/menuLateral/iconItems.svg";
import iconDeliveryMan from "../../assets/menuLateral/iconDeliveryMan.svg";
import iconUserRouter from "../../assets/menuLateral/iconUser.svg";
import iconSettings from "../../assets/menuLateral/iconSettings.svg";
import IconApp from "../../assets/menuLateral/IconApp.svg";
import IconDelivery from '../../assets/TopBar/IconDelivery.svg'
import Iconhome from '../../assets/TopBar/Iconhome.svg'
import IconTable from '../../assets/TopBar/IconTable.svg'
import IconComplement from '../../assets/IconComplement.svg';
import IconGroupComplement from '../../assets/IconGroupComplement.svg';
import IconSale from '../../assets/IconSale.svg';
import IconConsultSale from '../../assets/IconConsultSale.svg';
import IconFreight from '../../assets/menuLateral/IconFreight.svg';
import IconCurrency from '../../assets/IconCurrency.svg';
import IconGraphUp from '../../assets/IconGraphUp.svg';
import IconGraphDown from '../../assets/IconGraphDown.svg';
import IconCurrentAccount from '../../assets/IconCurrentAccount.svg';
import IconNoteEntry from '../../assets/IconNoteEntry.svg';
import IconBillsToPay from '../../assets/IconBillsToPay.svg';
import IconBillsToReceive from '../../assets/IconBillsToReceive.svg';
import IconConsultNoteEntry from '../../assets/IconConsultNoteEntry.svg';
import IconSection from '../../assets/IconSection.svg';
import IconNFE from '../../assets/IconNFE.svg';
import IconNCM from '../../assets/IconNCM.svg';
import {theme} from '../GlobalStyles/theme'
import Dropdown from 'react-bootstrap/Dropdown';
import { IconUser } from "../../components/SideBar/styles";
import CadEmpresas from "../../pages/public/CadEmpresas";

const TopBar = () => {

  const [SectionName, setSectionName] = useState('');
  const [img, setImg] = useState('');
  const [isOPenCompanies, setIsOPenCompanies] = useState(false);
  const colorSvg = JSON.parse(getToken('SISTESHOP')) ? theme.backgrounds.blue : theme.backgrounds.red2;
  let Companies = getToken('COMPANIES');
  Companies = Companies?.length > 0 ? JSON.parse(getToken('COMPANIES')) : {};

  const {usuarioId, 
        mesa,
        id_mesa,
        id_delivery,
        id_cliente,
        id_entregador,
        id_frete,
        atendenteId,
        produtoId,
        grupoComplementoId,
        complementoId,
        vendaId,
        idPagamento,
        idReceita,
        idDespesa,
        idContasPagar,
        idContasReceber,
        idContaCorrente,
        idEntrada,
        pageNumber,
        idFabricante,
        idSecao,
        iDNcm

  } = useParams();

  const link = useLocation();
  const history = useHistory();

  const topBarSectionName = [
    {icon: Iconhome, nome:'Dashboard', link:'/dashboard' },
    {icon: Iconhome, nome:'Dashboard', link:'/dashboard/mesas' },
    {icon: Iconhome, nome:'Dashboard', link:'/dashboard/delivery' },
    {icon: Iconhome, nome:'Dashboard', link:'/dashboard/frenteLoja' },
    {icon: iconAttendant, nome:'Atendentes', link:'/carregarAtendente'},
    {icon: iconAttendant, nome:'Atendente', link:`/atendente/${atendenteId}`},
    {icon: iconAttendant, nome:'Atendente', link:`/atendente`},
    {icon: iconItems, nome:'itens', link:'/listarProduto'},
    {icon: iconItems, nome:'itens', link:`/listarProduto/${pageNumber}`},
    {icon: iconItems, nome:'Item', link:'/produto'},
    {icon: iconItems, nome:'Item', link:'/produto'},
    {icon: iconItems, nome:'Categorias', link:'/Categoria'},
    {icon: iconItems, nome:`Item ${produtoId}`, link:`/produto/${produtoId}`},
    {icon: iconSettings, nome:`Parâmetro`, link:`/parametro/DadosEmpresa`},
    {icon: iconSettings, nome:`Parâmetro`, link:`/parametro/CardapioDigital`},
    {icon: iconSettings, nome:`Parâmetro`, link:`/parametro/Delivery`},
    {icon: iconSettings, nome:`Parâmetro`, link:`/parametro/Mesas`},
    {icon: iconSettings, nome:`Parâmetro`, link:`/parametro/App`},
    {icon: iconSettings, nome:`Parâmetro`, link:`/parametro/DFE`},
    {icon: iconSettings, nome:'parâmetro', link:'/parametro'},
    {icon: iconUserRouter, nome:'Usuários', link:'/listarUsuario'},
    {icon: iconUserRouter, nome:`Usuário`, link:`/usuario/${usuarioId}`},
    {icon: iconUserRouter, nome:`Usuário`, link:`/usuario/${usuarioId}`},
    {icon: iconUserRouter, nome:`Usuário`, link:`/usuario`},
    {icon: IconTable, nome:'Mesas', link:'/Carregarmesas'},
    {icon: IconTable, nome:'Mesas', link:`/Carregarmesas/${mesa}/${id_mesa}`},
    {icon: IconTable, nome:'Mesas', link:`/Carregarmesas/FormaPagamento/${mesa}/${id_mesa}`},
    {icon: IconTable, nome:`Mesa ${mesa}`, link:`/AdicionarProdutoMesa/${mesa}/${id_mesa}`},
    {icon: IconTable, nome:`Mesa ${mesa}`, link:`/AdicionarProdutoMesa/${mesa}/${id_mesa}/AdicionarProduto`},
    {icon: IconTable, nome:`Mesa ${mesa}`, link:`/AdicionarProdutoMesa/FormaPagamento/${mesa}/${id_mesa}`},
    {icon: IconDelivery, nome:'Delivery', link:'/Delivery'},
    {icon: IconDelivery, nome:`Delivery ${id_delivery}`, link:`/Delivery/${id_delivery}`},
    {icon: IconDelivery, nome:`Novo delivery`, link:`/Delivery/LançarDelivery`},
    {icon: IconDelivery, nome:`Delivery ${id_delivery}`, link:`/AdicionarProdutoDelivery/${id_delivery}`},
    {icon: IconDelivery, nome:`Delivery ${id_delivery}`, link:`/AdicionarProdutoDelivery/${id_delivery}/AdicionarProduto`},
    {icon: IconDelivery, nome:`Delivery ${id_delivery}`, link:`/AdicionarProdutoDelivery/FormaPagamento/${id_delivery}`},
    {icon: IconSale, nome:`Venda`, link:`/AdicionarProdutoVenda`},
    {icon: IconSale, nome:`Venda`, link:`/AdicionarProdutoVenda/AdicionarProduto`},
    {icon: IconSale, nome:`Venda`, link:`/AdicionarProdutoVenda/FormaPagamento`},
    {icon: IconSale, nome:`NFCE`, link:`/AdicionarProdutoNFCE`},
    {icon: IconSale, nome:`NFCE`, link:`/AdicionarProdutoNFCE/AdicionarProduto`},
    {icon: IconSale, nome:`NFCE`, link:`/AdicionarProdutoNFCE/FormaPagamento`},
    {icon: IconNFE, nome:`NF-e Entrada`, link:`/AdicionarProdutoEntradaNFE`},
    {icon: IconNFE, nome:`NF-e Entrada`, link:`/AdicionarProdutoEntradaNFE/AdicionarProduto`},
    {icon: IconNFE, nome:`NF-e Entrada Devo.`, link:`/AdicionarProdutoEntradaNFEDevolucao`},
    {icon: IconNFE, nome:`NF-e Entrada Devo.`, link:`/AdicionarProdutoEntradaNFEDevolucao/AdicionarProduto`},
    {icon: IconNFE, nome:`NF-e Entrada Boni.`, link:`/AdicionarProdutoEntradaNFEBonificacao`},
    {icon: IconNFE, nome:`NF-e Entrada Boni.`, link:`/AdicionarProdutoEntradaNFEBonificacao/AdicionarProduto`},
    {icon: IconNFE, nome:`NF-e Entrada Avar/Per.`, link:`/AdicionarProdutoEntradaNFEAvariaPerca`},
    {icon: IconNFE, nome:`NF-e Entrada Avar/Per.`, link:`/AdicionarProdutoEntradaNFEAvariaPerca/AdicionarProduto`},
    {icon: IconNFE, nome:`NF-e Entrada Outros.`, link:`/AdicionarProdutoEntradaNFEOutros`},
    {icon: IconNFE, nome:`NF-e Entrada Outros.`, link:`/AdicionarProdutoEntradaNFEOutros/AdicionarProduto`},

    {icon: IconNFE, nome:`NF-e Saída`, link:`/AdicionarProdutoSaidaNFE`},
    {icon: IconNFE, nome:`NF-e Saída`, link:`/AdicionarProdutoSaidaNFE/AdicionarProduto`},
    {icon: IconNFE, nome:`NF-e Saída Devo.`, link:`/AdicionarProdutoSaidaNFEDevolucao`},
    {icon: IconNFE, nome:`NF-e Saída Devo.`, link:`/AdicionarProdutoSaidaNFEDevolucao/AdicionarProduto`},
    {icon: IconNFE, nome:`NF-e Saída Boni.`, link:`/AdicionarProdutoSaidaNFEBonificacao`},
    {icon: IconNFE, nome:`NF-e Saída Boni.`, link:`/AdicionarProdutoSaidaNFEBonificacao/AdicionarProduto`},
    {icon: IconNFE, nome:`NF-e Saída Avar/Per.`, link:`/AdicionarProdutoSaidaNFEAvariaPerca`},
    {icon: IconNFE, nome:`NF-e Saída Avar/Per.`, link:`/AdicionarProdutoSaidaNFEAvariaPerca/AdicionarProduto`},
    {icon: IconNFE, nome:`NF-e Saída Outros.`, link:`/AdicionarProdutoSaidaNFEOutros`},
    {icon: IconNFE, nome:`NF-e Saída Outros.`, link:`/AdicionarProdutoSaidaNFEOutros/AdicionarProduto`},
    {icon: IconPerson, nome:'Clientes', link:'/listarCliente'},
    {icon: IconPerson, nome:'Cliente', link:`/listarCliente/${id_cliente}`},
    {icon: IconPerson, nome:'Cliente', link:`/cliente`},
    {icon: iconDeliveryMan, nome:'Entregadores', link:'/listarEntregador'},
    {icon: iconDeliveryMan, nome:'Entregador', link:'/Entregador'},
    {icon: iconDeliveryMan, nome:'Entregador', link:`/Entregador/${id_entregador}`},
    {icon: IconApp, nome:'Qr Code', link:'/QrCode'},
    {icon: Iconhome, nome:`Fretes`, link:`/listarFrete`},
    {icon: IconFreight, nome:`Frete`, link:`/frete/${id_frete}`},
    {icon: Iconhome, nome:`Frete`, link:`/frete`},
    {icon: IconComplement, nome:`Complementos`, link:`/listarComplemento`},
    {icon: IconComplement, nome:`Complemento`, link:`/Complemento`},
    {icon: IconComplement, nome:`Complemento ${complementoId}`, link:`/Complemento/${complementoId}`},
    {icon: IconGroupComplement, nome:`Grupo de Complementos`, link:`/listarGrupoComplemento`},
    {icon: IconGroupComplement, nome:`Grupo de Complemento`, link:`/GrupoComplemento`},
    {icon: IconGroupComplement, nome:`Grupo de Complemento ${grupoComplementoId}`, link:`/GrupoComplemento/${grupoComplementoId}`},
    {icon: IconPerson, nome:`Fabricante`, link:`/listarFabricante`},
    {icon: IconPerson, nome:`Fabricante`, link:`/Fabricante`},
    {icon: IconPerson, nome:`Fabricante ${idFabricante}`, link:`/Fabricante/${idFabricante}`},
    {icon: IconSection, nome:`Seção`, link:`/listarSecao`},
    {icon: IconSection, nome:`Seção`, link:`/Secao`},
    {icon: IconSection, nome:`Seção ${idSecao}`, link:`/Secao/${idSecao}`},
    {icon: IconNCM, nome:`Ncm`, link:`/listarNcm`},
    {icon: IconNCM, nome:`Ncm`, link:`/Ncm`},
    {icon: IconNCM, nome:`Ncm ${iDNcm}`, link:`/Ncm/${iDNcm}`},
    {icon: IconConsultSale, nome:`Consultar Vendas`, link:`/listarVendas`},
    {icon: IconConsultSale, nome:`Venda ${vendaId}`, link:`/venda/${vendaId}`},
    {icon: IconCurrency , nome:`Consultar Forma de Pagamento`, link:`/listarFormaPagamento`},
    {icon: IconCurrency, nome:`Forma de Pagamento`, link:`/FormaPagamento/${idPagamento}`},
    {icon: IconCurrency, nome:`Forma de Pagamento`, link:`/FormaPagamento`},
    {icon: IconGraphUp , nome:`Consultar Receita`, link:`/listarReceita`},
    {icon: IconGraphUp, nome:`Receita`, link:`/Receita/${idReceita}`},
    {icon: IconGraphUp, nome:`Receita`, link:`/Receita`},
    {icon: IconGraphDown , nome:`Consultar Despesa`, link:`/listarDespesa`},
    {icon: IconGraphDown, nome:`Despesa`, link:`/Despesa/${idDespesa}`},
    {icon: IconGraphDown, nome:`Despesa`, link:`/Despesa`},
    {icon: IconBillsToPay , nome:`Consultar Pagar`, link:`/listarContasPagar`},
    {icon: IconBillsToPay , nome:`Consultar Pagar`, link:`/listarContasPagar/${pageNumber}`},
    {icon: IconBillsToPay , nome:`Consultar Pagas`, link:`/listarContasPagas`},
    {icon: IconBillsToPay , nome:`Consultar Pagas`, link:`/listarContasPagas/${pageNumber}`},
    {icon: IconBillsToPay , nome:`Consultar Canceladas`, link:`/listarContasCanceladas`},
    {icon: IconBillsToPay , nome:`Consultar Canceladas`, link:`/listarContasCanceladas/${pageNumber}`},
    {icon: IconBillsToPay, nome:`Pagar`, link:'/ContasPagar'},
    {icon: IconBillsToPay, nome:`Pagar`, link:`/ContasPagar/${idContasPagar}/${pageNumber}`},
    {icon: IconBillsToPay, nome:`Pagar`, link:`/ContasPagar/${idContasPagar}`},
    {icon: IconBillsToPay, nome:`Pagas`, link:'/ContasPagas'},
    {icon: IconBillsToPay, nome:`Pagas`, link:`/ContasPagas/${idContasPagar}/${pageNumber}`},
    {icon: IconBillsToPay, nome:`Pagas`, link:`/ContasPagas${idContasPagar}`},
    {icon: IconBillsToPay, nome:`Canceladas`, link:'/ContasCanceladas'},
    {icon: IconBillsToPay, nome:`Canceladas`, link:`/ContasCanceladas/${idContasPagar}`},
    {icon: IconBillsToPay, nome:`Canceladas`, link:`/ContasCanceladas/${idContasPagar}/${pageNumber}`},
    {icon: IconBillsToReceive , nome:`Consultar Receber`, link:`/listarContasReceber`},
    {icon: IconBillsToReceive , nome:`Consultar Receber`, link:`/listarContasReceber/${pageNumber}`},
    {icon: IconBillsToReceive , nome:`Consultar Receber`, link:`/listarContasRecebidas`},
    {icon: IconBillsToReceive , nome:`Consultar Receber`, link:`/listarContasReceberCanceladas/${pageNumber}`},
    {icon: IconBillsToReceive , nome:`Consultar Receber`, link:`/listarContasReceberCanceladas`},
    {icon: IconBillsToReceive , nome:`Consultar Receber`, link:`/listarContasRecebidas/${pageNumber}`},
    {icon: IconBillsToReceive , nome:`Consultar Receber`, link:`/listarContasReceber/${idContasReceber}/${pageNumber}`},
    {icon: IconBillsToReceive, nome:`Receber`, link:`/ContasReceber/${idContasReceber}/${pageNumber}`},
    {icon: IconBillsToReceive, nome:`Receber`, link:`/ContasReceber/${idContasReceber}`},
    {icon: IconBillsToReceive, nome:`Receber`, link:`/ContasReceber`},
    {icon: IconCurrentAccount , nome:`Consultar Conta Corrente`, link:`/listarContaCorrente`},
    {icon: IconCurrentAccount, nome:`Conta Corrente`, link:`/ContaCorrente/${idContaCorrente}`},
    {icon: IconCurrentAccount, nome:`Conta Corrente`, link:`/ContaCorrente`},
    {icon: IconConsultNoteEntry , nome:`Consultar Entrada`, link:`/listarNotasEntrada`},
    {icon: IconConsultNoteEntry, nome:`Entrada ${idEntrada}`, link:`/entrada/${idEntrada}`},
    {icon: IconNoteEntry , nome:`Entrada de Nota`, link:`/AdicionarProdutoEntrada`},
    {icon: IconNoteEntry , nome:`Entrada de Nota Forma de Pagamento`, link:`/AdicionarProdutoEntrada/FormaPagamento`},
    {icon: IconNoteEntry, nome:`Entrada de Nota Produtos`, link:`/AdicionarProdutoEntrada/AdicionarProduto`},
    {icon: IconNoteSingle , nome:`Nota Avulsa`, link:`/NotaAvulsa`},
    {icon: IconNoteSingle, nome:`Nota Avulsa Produtos`, link:`/NotaAvulsa/AdicionarProduto`},
    {icon: IconInventory , nome:`Inventário`, link:`/Inventario`},
    {icon: IconInventory, nome:`Inventário Produtos`, link:`/Inventario/AdicionarProduto`},
  ]

  useEffect(() => {
    verifyNameUrl()
  }, [link.pathname])

  const verifyNameUrl = () => { 
    topBarSectionName.filter(item => {
      if (link.pathname === item.link) {
        setSectionName(item.nome);
        setImg(item.icon);
      }
      return
    })
  }

  const handleClickCompanies = () => {
    if(Companies?.cnpj) setIsOPenCompanies(true);
  }

  const closeModalCampanies = () => {
    setIsOPenCompanies(false);
  }

  return (
    <S.Container >
      <S.ContainerLogoFood>
        <S.ContainerWrapperLogoFood >
          <S.IconLogo>
              <img  src={JSON.parse(getToken('SISTESHOP')) ? IconLogoBlue : IconLogo} alt='Ícone da logo' onClick={() => history.push('/dashboard')}/>
          </S.IconLogo>
        </S.ContainerWrapperLogoFood>
      </S.ContainerLogoFood>

      <S.InforHeaderWrapper>
        <S.StablishmentNameContainer cursor={Companies?.cnpj?.length > 0 ? 'pointer' : 'auto'} onClick={handleClickCompanies}>
          <S.IconStablishment  stroke={colorSvg} />
          <p>{getToken('NOMEEMPRESA') }</p>
        </S.StablishmentNameContainer>

        <S.Button>
          <S.ImageContainer>
            <img src={img} alt='Ícone de imagem'/>
          </S.ImageContainer>
          {SectionName}
        </S.Button>

        <S.UserInfoContainer>
          <S.CertificateInfoContainer>
            <S.iconCalendar fill={colorSvg}/>

            <p>{`restam: ${getToken("DIAS")} dias`}</p>
            
            <S.ImageContainer>
              <img src={iconInfo} alt='Ícone info'/>
            </S.ImageContainer>
          </S.CertificateInfoContainer>
          
          <S.UserInfo>
            <p>{`${getToken("LOGIN")}`}</p>
            
            <S.ImageUserContainer>
              <img src={iconUser} alt='Ícone do usuário'/>
            </S.ImageUserContainer>
          </S.UserInfo>

          <S.UserInfoDropDown>
            <Dropdown>
              <Dropdown.Toggle variant='none' bsPrefix={'my-css'}>
                <S.ContainerDropDownIconUser>
                  <S.ImageUserContainer>
                    <img src={iconUser} alt='Ícone do usuário'/>
                  </S.ImageUserContainer>
                  <Style.IconArrowDown/>

                </S.ContainerDropDownIconUser>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="dropdown-icon">
                  <S.ImageUserContainer width={'20px'} height={'20px'}>
                    <IconUser 
                      fill={colorSvg ? theme.backgrounds.red2 : theme.backgrounds.blue4}
                      stroke={colorSvg ? theme.backgrounds.red2 : theme.backgrounds.blue4}
                    />
                  </S.ImageUserContainer>
                  {getToken('LOGIN')}
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-icon">
                  <S.iconCalendar fill={colorSvg}/>
                  {`restam: ${getToken("DIAS")} dias`}
                  </Dropdown.Item>
                <Dropdown.Item className="dropdown-icon establishment-name" onClick={handleClickCompanies}>
                  <S.IconStablishment  stroke={colorSvg} />
                  {getToken('NOMEEMPRESA')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </S.UserInfoDropDown>

        </S.UserInfoContainer>
      </S.InforHeaderWrapper>

      {
        isOPenCompanies ? (
          <CadEmpresas 
            onClose={closeModalCampanies} 
            resultado={Companies}
          />
          ): null

      }
    </S.Container>
 
  );
};

export default TopBar;
