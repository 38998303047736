import axios from "axios";
import {incript} from './gerandoMD5ParaEndPoints'
import {getToken} from  "./auth"

const baseApi = (baseURL) => {
    const api = axios.create({
        baseURL: baseURL
    });
    
    api.interceptors.request.use(async (config) => {
        
        const TokenKey = getToken('CNPJ');
        const  url = config.url;

        if (config.url.endsWith('cadastrarUsuario')) {
            const  url = config.url;
            let token = `Basic ${incript('20798514000141',url)}`;
            config.headers['Authorization'] = token; 
            
        }
        else if (config.url.endsWith('login')) {
            const  url = config.url;
            let token = `Basic ${incript('20798514000141',url)}`;
            config.headers['Authorization'] = token;   
        }
        else if (config.url.endsWith('listarUsuario') || config.url.endsWith('carregarPermissaoAcesso')) {
            const  url = config.url;
            let token = `Basic ${incript('20798514000141',url)}`;
            config.headers['Authorization'] = token;   
        } 
        else if (config.url.endsWith('getStringConexao')) {
            const  url = config.url;
            let token = `Basic ${incript('20798514000105',url)}`;
            config.headers['Authorization'] = token;   
        }
        else if (config.url.endsWith('recuperarSenha')){
            const  url = config.url;
            let token = `Basic ${incript('20798514000141',url)}`;
            config.headers['Authorization'] = token; 
        }
        else{
            let token = `Basic ${incript(TokenKey,url)}`;
            config.headers['Authorization'] = token; 

        }  
        return config;
    })

    return api
}


export default baseApi;
