import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import { AccessDenied } from "../components/AccessDenied";
import { entrar, getToken } from "../services/auth";

let rulesPermissionUserLocalStorage = getToken('RULESPERMISSIONUSER');
rulesPermissionUserLocalStorage = rulesPermissionUserLocalStorage !== 'undefined' && rulesPermissionUserLocalStorage !== null ? JSON.parse(rulesPermissionUserLocalStorage) : []

let adm = getToken('ADM') ?? 'N';

export const RulesPermissionUserContext  = createContext([]);

export const RulesPermissionUserProvider = ({children}) => {
    const [rulesPermissionUser, setRulesPermissionUser] = useState([]);
    const [accessDenied, setAccessDenied] = useState(false);
    const history = useHistory();

    useEffect(() => {
        reloadingPage();
    }, [])


    
    const verifyAdm = () => {
        if(adm === 'S') {
            setAccessDenied(false);
            return true;
        }
        return false;
    }

    const findIdRulesPermission = useCallback((id) => {
        const resultAdm = verifyAdm();

        const resultArrayRules = rulesPermissionUser.filter(item => item.permissao === id);
        const [, path] = history.location.pathname.split('/');

        if(resultArrayRules.length > 0 || resultAdm) {
            setAccessDenied(false);
            return true;
        }
        if(path !== 'dashboard') setAccessDenied(true);
        return false;
    }, [rulesPermissionUser])


    const reloadingPage = useCallback(() => {
        if(rulesPermissionUserLocalStorage.length > 0 && rulesPermissionUser.length === 0) {
            updateStateRulesPermissionUser([...rulesPermissionUserLocalStorage]);
        }
    }, [rulesPermissionUser])

    const login = useCallback((permission) => {
        rulesPermissionUserLocalStorage =  permission
        adm = getToken('ADM') ?? 'N';
        entrar('RULESPERMISSIONUSER', JSON.stringify(permission));
        setRulesPermissionUser([...permission]);
    }, [])

    const updateStateRulesPermissionUser = useCallback((value) =>  setRulesPermissionUser([...value]), []);

    const closeModalAcessDenied = useCallback(() => {
        setAccessDenied(!accessDenied);
    }, [accessDenied]);

    
    const matchRoutePermission = useCallback((key) => {
        if(adm === 'S' ) return true
        for (let index = 0; index < rulesPermissionUserLocalStorage.length; index++) {
            if (rulesPermissionUserLocalStorage[index].permissao === key) return true
            
        }
        return false;

    }, [])

    const contextValue = useMemo(() => ({    
        rulesPermissionUser,
        updateStateRulesPermissionUser,
        login,
        findIdRulesPermission,
        closeModalAcessDenied,
        matchRoutePermission,
        setAccessDenied,
        accessDenied
    }), [rulesPermissionUser, updateStateRulesPermissionUser, login, findIdRulesPermission, closeModalAcessDenied, matchRoutePermission, accessDenied])

    return (
        <RulesPermissionUserContext.Provider value={contextValue}>
            {
                accessDenied && createPortal(
                    <RulesPermissionUserContext.Provider value={contextValue}>
                        <AccessDenied/>
                    </RulesPermissionUserContext.Provider>,
                    document.body
                )
            }


            {children}
        </RulesPermissionUserContext.Provider>
    )
}

