import { useContext } from "react";
import { RulesPermissionUserContext } from "../contexts/RulesPermissionUser";

export const contextClass = (Component) => {
    const WrapperContextClass = (props) => {
      const {rulesPermissionUser, updateStateRulesPermissionUser, findIdRulesPermission, login} = useContext(RulesPermissionUserContext);
      return <Component 
              rulesPermissionUser={rulesPermissionUser} 
              updateStateRulesPermissionUser={updateStateRulesPermissionUser}
              findIdRulesPermission={findIdRulesPermission}
              login={login}
              {...props}
          />
  }

  return WrapperContextClass;

}