import baseAPI from './api';
import baseURLs from '../configs/baseURLs';


class CountDeliveryAndTable {
    constructor (){
        this.apiDelivery = baseAPI(baseURLs.API_DELIVERY_COUNT);
        this.apiTable = baseAPI(baseURLs.API_TABLE_COUNT);
    }

    async getCountDelivery(deliveryModal) {
        const result = await this.apiDelivery.post('getQtdDelivery', deliveryModal);
        return result.data

    }

    async getCountTable(tableModal) {
        const result = await this.apiTable.post('getQtdMesa', tableModal);
        return result.data

    }
}

export default CountDeliveryAndTable