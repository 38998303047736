import { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { RulesPermissionUserContext } from "../contexts/RulesPermissionUser";

const permissionRedirect = {
    'AdicionarProdutoVenda':'6.1.1',
    'AdicionarProdutoNFCE':'6.2.1.1',
    'AdicionarProdutoEntradaNFE':'6.2.2.2',
    'AdicionarProdutoSaidaNFE':'6.2.3.2',
    'AdicionarProdutoEntrada':'6.3.1',
    'listarProduto':'1.5.4',
    'listarComplemento':'1.1.4',
    'listarGrupoComplemento':'1.2.4',
    'listarFabricante':'1.3.4',
    'listarSecao':'1.4.4',
    'NotaAvulsa':'3.1.1',
    'Inventario':'3.2.1',
    'listarCliente':'2.1.4',
    'carregarAtendente':'2.2.4',
    'listarEntregador':'2.3.4',
    'listarUsuario':'2.4.4',
    'listarFormaPagamento':'4.1.4',
    'listarReceita':'4.2.4',
    'listarContaCorrente':'5.3.4',
    'listarDespesa':'4.4.4',
    'listarContasPagar':'4.5.4',
    'ContasPagar':'4.5.4',
    'listarContasPagas':'4.5.4',
    'listarContasCanceladas':'4.5.4',
    'listarContasReceber':'4.6.4',
    'ContasReceber':'4.6.4',
    'listarContasRecebidas':'4.6.4',
    'listarContasReceberCanceladas':'4.6.4',
    'listarFrete':'6.4.4',
    'parametro':'6.5.2',
    'listarVendas': '6.6.1',
    'venda': '6.6.1',
    'listarNotasEntrada': '6.7.1',
    'entrada': '6.7.1',
    'Carregarmesas': '6.8.1',
    'AdicionarProdutoMesa': '6.8.1',
    'dashboard': '6.10.1',
    'Delivery': '6.9.4',
    'AdicionarProdutoDelivery': '6.9.4'
}

export const PermissionRoutesPrimaries = ({children,rest}) => {
    const  {matchRoutePermission, setAccessDenied} = useContext(RulesPermissionUserContext);
    const {pathname} = rest.location;
    const [,path] = pathname.split('/');

    useEffect(() => {
        if(permissionRedirect[path] && (path !== 'dashboard')){
            if (!matchRoutePermission( permissionRedirect[path])) setAccessDenied(true);
        }

    }, [path])


    if(permissionRedirect[path] && (path !== 'dashboard')){
        if (!matchRoutePermission( permissionRedirect[path])) return <Redirect to={{ pathname: '/dashboard', state:{from: rest.location } }} />
    }

    return(
        children
    )

}