import { useMemo } from "react";
import { useState } from "react";
import { createContext } from "react";

const socketDeliveryContext = createContext([]);

export const SocketDeliverProvider = ({children}) => {
    const [countDelivery, setCountDelivery] = useState(0)

    const contextValues = useMemo(() => ({
        countDelivery,
        setCountDelivery
    }), [countDelivery])

    return(
        <socketDeliveryContext.Provider value={contextValues}>
            {
                children
            }
        </socketDeliveryContext.Provider>
    )
}
