import * as Style from './styles';

export const List = ({children, ...rest}) => {
    return(
        <Style.BoxContainer {...rest}>
            <Style.BoxMainContent>
                {children}
            </Style.BoxMainContent>
        </Style.BoxContainer>
    )
}
