import React, {Suspense, lazy} from "react";

// aqui eu faço o roteamento usando o BrowserRouter 
import { 
    BrowserRouter as Router,
    Switch,
    Route, 
    useRouteMatch,  
} from "react-router-dom";
import socketIO from 'socket.io-client';
import RoutePrivate from './routes-wrapper';
import { GlobalStyle } from "../shared/GlobalStyles";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Loading } from "./styles";
import Spinner from 'react-bootstrap/Spinner';
import { theme } from "../shared/GlobalStyles/theme";
import { ThemeProvider } from "styled-components";
import { RulesPermissionUserProvider } from "../contexts/RulesPermissionUser";
import { SocketDeliverProvider } from "../contexts/socketDelivery";
const Dashboard = lazy(() => import("../components/Dashboard"));
const Login = lazy(() => import("../pages/public/login"));
const ListaAtendentesPage = lazy(() => import(".././pages/secure/listaAtendente"));
const ListarProduto = lazy(() => import(".././pages/secure/listaProduto"));
const Parameter = lazy(() => import(".././pages/secure/Parameter"));
const SemLogin = lazy(() => import("../pages/public/semLogin"));
const ListarUsuario = lazy(() => import("../pages/secure/listaUsuario"));
const QrCode = lazy(() => import("../pages/secure/QrCode"));
const listarMesa = lazy(() => import("../pages/secure/listaMesas"));
const ProdutoMesa = lazy(() => import("../pages/secure/AddProdutoMesa"));
const Delivery = lazy(() => import("../pages/secure/Delivery"));
const Client = lazy(() => import("../pages/secure/Client"));
const AddProdutoDelivery = lazy(() => import("../pages/secure/AddProdutoDelivery"));
const Complement = lazy(() => import("../pages/secure/Complement"));
const DeliveryMan = lazy(() => import("../pages/secure/DeliveryMan"));
const Freight = lazy(() => import("../pages/secure/Freight"));
const GroupComplement = lazy(() => import("../pages/secure/GroupComplement"));
const ConsultSales = lazy(() => import("../pages/secure/ConsultSales"));
const RecoverPassword = lazy(() => import("../pages/public/RecoverPassword"));
const SaleLocal = lazy(() => import("../pages/secure/SaleLocal"));
const SaleNFCELocal = lazy(() => import("../pages/secure/SaleNFCELocal"));
const NoteEntry = lazy(() => import("../pages/secure/NoteEntry"));
const NoteSigle = lazy(() => import("../pages/secure/NoteSigle"));
const Inventory = lazy(() => import("../pages/secure/Inventory"));
const Budget = lazy(() => import("../pages/secure/Budget"));
const NoteEntryNFE = lazy(() => import("../pages/secure/NoteEntryNFE"));
const NoteExitNFELocal = lazy(() => import("../pages/secure/NoteExitNFELocal"));
const FormOfPayment = lazy(() => import("../pages/secure/FormOfPayment"));
const Expense = lazy(() => import("../pages/secure/Expense"));
const Revenue = lazy(() => import("../pages/secure/Revenue"));
const CurrentAccount = lazy(() => import("../pages/secure/CurrentAccount"));
const BillsToPay = lazy(() => import("../pages/secure/BillsToPay"));
const BillsToReceive = lazy(() => import("../pages/secure/BillsToReceive"));
const ConsultNoteEntry = lazy(() => import("../pages/secure/ConsultNoteEntry"));
const Manufactory = lazy(() => import("../pages/secure/Manufactory"));
const Section = lazy(() => import("../pages/secure/Section"));
// const Cfop = lazy(() => import("../pages/secure/Cfop"));
const Ncm = lazy(() => import("../pages/secure/Ncm"));
const NoteEntryNFeReturn = lazy(() => import("../pages/secure/NoteEntryNFEReturn"));
const NoteEntryNFEBonus = lazy(() => import("../pages/secure/NoteEntryNFEBonus"));
const NoteEntryNFEBreakdownMiss = lazy(() => import("../pages/secure/NoteEntryNFEBreakdownMiss"));
const NoteEntryNFEOthers = lazy(() => import("../pages/secure/NoteEntryNFEOthers"));
const NoteExitNFELocalReturn = lazy(() => import("../pages/secure/NoteExitNFELocalReturn"));
const NoteExitNFELocalBonus = lazy(() => import("../pages/secure/NoteExitNFELocalBonus"));
const NoteExitNFELocalBreakdownMiss = lazy(() => import("../pages/secure/NoteExitNFELocalBreakdownMiss"));
const NoteExitNFELocalOthers = lazy(() => import("../pages/secure/NoteExitNFELocalOthers"));
const socket = socketIO.connect('https://api.sistefood.com.br:4000');

export default function Routes() {   
    return(
        <Router>
            <Suspense fallback={<Loading><Spinner animation="border" variant="primary" /></Loading>}>
                <ThemeProvider theme={theme}>             
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                        <GlobalStyle/>
                        <RulesPermissionUserProvider>
                            <SocketDeliverProvider>
                                <Switch>
                                    <RoutePrivate socket={socket} exact path='/dashboard' component={Dashboard}/>
                                    <RoutePrivate socket={socket} exact path='/dashboard/mesas' component={Dashboard}/>
                                    <RoutePrivate socket={socket} exact path='/dashboard/delivery' component={Dashboard}/>
                                    <RoutePrivate socket={socket} exact path='/dashboard/frenteLoja' component={Dashboard}/>
                                    <RoutePrivate socket={socket} exact path='/carregarAtendente' component={AtendentesRoutes} />
                                    <RoutePrivate socket={socket} exact path='/atendente/:atendenteId?' component={AtendentesRoutes} />
                                    <RoutePrivate socket={socket} exact path='/produto/:produtoId?/:pageNumber?' component={ProdutosRoutes} />
                                    <RoutePrivate socket={socket} exact path='/listarProduto/:pageNumber?' component={ProdutosRoutes} />
                                    <RoutePrivate socket={socket} exact path='/Categoria' component={ProdutosRoutes} />
                                    <RoutePrivate socket={socket} exact path='/parametro' component={Parameter} />
                                    <RoutePrivate socket={socket} exact path='/parametro/DadosEmpresa' component={Parameter} />
                                    <RoutePrivate socket={socket} exact path='/parametro/CardapioDigital' component={Parameter} />
                                    <RoutePrivate socket={socket} exact path='/parametro/Delivery' component={Parameter}/>
                                    <RoutePrivate socket={socket} exact path='/parametro/Mesas' component={Parameter} />
                                    <RoutePrivate socket={socket} exact path='/parametro/app' component={Parameter} />
                                    <RoutePrivate socket={socket} exact path='/parametro/DFE' component={Parameter} />
                                    <RoutePrivate socket={socket} exact path='/listarUsuario' component={UsuarioRoutes}/>
                                    <RoutePrivate socket={socket} exact path='/usuario/:usuarioId?'component={UsuarioRoutes}/>
                                    <Route exact path='/' component={Login} />
                                    <Route exact path='/semLogin' component={SemLogin}/>
                                    <Route exact path='/RecuperarSenha' component={RecoverPassword} />
                                    <RoutePrivate socket={socket} exact path='/QrCode' component={QrCode} />
                                    <RoutePrivate socket={socket} exact path='/Carregarmesas/' component={listarMesa} />
                                    <RoutePrivate socket={socket} exact path='/Carregarmesas/:mesa/:id_mesa'  component={listarMesa}  />
                                    <RoutePrivate socket={socket} exact path='/Carregarmesas/FormaPagamento/:mesa/:id_mesa' component={listarMesa}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoMesa/:mesa/:id_mesa' component={ProdutoMesa}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoMesa/:mesa/:id_mesa/AdicionarProduto/:editProductMobile?' component={ProdutoMesa}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoMesa/FormaPagamento/:mesa/:id_mesa' component={ProdutoMesa} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoDelivery/FormaPagamento/:id_delivery' component={AddProdutoDelivery} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoDelivery/:id_delivery?' component={AddProdutoDelivery} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoDelivery/:id_delivery/AdicionarProduto/:editProductMobile?' component={AddProdutoDelivery} />
                                    <RoutePrivate exact path='/Delivery/:id_delivery?' component={Delivery} socket={socket} />
                                    <RoutePrivate exact path='/Delivery/LançarDelivery' component={Delivery}  socket={socket}/>
                                    <RoutePrivate exact path='/Delivery/Dashboard' component={Delivery} socket={socket}/>
                                    <RoutePrivate socket={socket} exact path='/listarCliente/:id_cliente?' component={Client} />
                                    <RoutePrivate socket={socket} exact path='/cliente' component={Client} />
                                    <RoutePrivate socket={socket} exact path='/endereco/:id_endereco?' component={Client} />
                                    <RoutePrivate socket={socket} exact path='/listarEntregador' component={DeliveryMan} />
                                    <RoutePrivate socket={socket} exact path='/Entregador/:id_entregador?' component={DeliveryMan} />
                                    <RoutePrivate socket={socket} exact path='/listarFrete' component={Freight} />
                                    <RoutePrivate socket={socket} exact path='/frete/:id_frete?' component={Freight} />
                                    <RoutePrivate socket={socket} exact path='/listarComplemento' component={Complement} />
                                    <RoutePrivate socket={socket} exact path='/Complemento/:complementoId?' component={Complement} />
                                    <RoutePrivate socket={socket} exact path='/listarGrupoComplemento' component={GroupComplement} />
                                    <RoutePrivate socket={socket} exact path='/GrupoComplemento/:grupoComplementoId?' component={GroupComplement} />
                                    <RoutePrivate socket={socket} exact path='/listarVendas' component={ConsultSales} />
                                    <RoutePrivate socket={socket} exact path='/venda/:vendaId' component={ConsultSales} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoVenda' component={SaleLocal}  fiscal={'N'}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoVenda/AdicionarProduto/:editProductMobile?' component={SaleLocal}  fiscal={'N'}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoVenda/FormaPagamento' component={SaleLocal}  fiscal={'N'}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntrada/FormaPagamento' component={NoteEntry} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntrada/AdicionarProduto/:editProductMobile?' component={NoteEntry} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntrada' component={NoteEntry} />
                                    <RoutePrivate socket={socket} exact path='/NotaAvulsa' component={NoteSigle} />
                                    <RoutePrivate socket={socket} exact path='/NotaAvulsa/AdicionarProduto/:editProductMobile?' component={NoteSigle}/>
                                    <RoutePrivate socket={socket} exact path='/NotaAvulsa/FormaPagamento' component={NoteSigle}/>
                                    <RoutePrivate socket={socket} exact path='/Inventario/AdicionarProduto/:editProductMobile?' component={Inventory} />
                                    <RoutePrivate socket={socket} exact path='/Inventario/FormaPagamento' component={Inventory} />
                                    <RoutePrivate socket={socket} exact path='/Inventario' component={Inventory} />
                                    <RoutePrivate socket={socket} exact path='/Orcamento/AdicionarProduto/:editProductMobile?' component={Budget} />
                                    <RoutePrivate socket={socket} exact path='/Orcamento/FormaPagamento' component={Budget} />
                                    <RoutePrivate socket={socket} exact path='/Orcamento' component={Budget} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoNFCE' component={SaleNFCELocal} fiscal={'S'}  />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoNFCE/AdicionarProduto/:editProductMobile?' component={SaleNFCELocal} fiscal={'S'} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoNFCE/FormaPagamento' component={SaleNFCELocal} fiscal={'S'} />

                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntradaNFE/AdicionarProduto/:editProductMobile?' component={NoteEntryNFE} nfe={'S'} tipo_emissao={'1'}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntradaNFE' component={NoteEntryNFE} nfe={'S'} tipo_emissao={'1'}/>

                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntradaNFEDevolucao/AdicionarProduto/:editProductMobile?' component={NoteEntryNFeReturn} nfe={'S'} tipo_emissao={'2'} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntradaNFEDevolucao' component={NoteEntryNFeReturn} nfe={'S'} tipo_emissao={'2'}/>

                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntradaNFEBonificacao/AdicionarProduto/:editProductMobile?' component={NoteEntryNFEBonus} nfe={'S'} tipo_emissao={'3'} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntradaNFEBonificacao' component={NoteEntryNFEBonus} nfe={'S'} tipo_emissao={'3'}/>

                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntradaNFEAvariaPerca/AdicionarProduto/:editProductMobile?' component={NoteEntryNFEBreakdownMiss} nfe={'S'} tipo_emissao={'4'} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntradaNFEAvariaPerca' component={NoteEntryNFEBreakdownMiss} nfe={'S'} tipo_emissao={'4'}/>

                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntradaNFEOutros/AdicionarProduto/:editProductMobile?' component={NoteEntryNFEOthers} nfe={'S'} tipo_emissao={'5'} />
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoEntradaNFEOutros' component={NoteEntryNFEOthers} nfe={'S'} tipo_emissao={'5'}/>

                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoSaidaNFE/AdicionarProduto/:editProductMobile?' component={NoteExitNFELocal} nfe={'S'} tipo_emissao={'1'}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoSaidaNFE' component={NoteExitNFELocal} nfe={'S'} tipo_emissao={'1'}/>
                                    
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoSaidaNFEDevolucao/AdicionarProduto/:editProductMobile?' component={NoteExitNFELocalReturn} nfe={'S'} tipo_emissao={'2'}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoSaidaNFEDevolucao' component={NoteExitNFELocalReturn} nfe={'S'} tipo_emissao={'2'}/>

                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoSaidaNFEBonificacao/AdicionarProduto/:editProductMobile?' component={NoteExitNFELocalBonus} nfe={'S'} tipo_emissao={'3'}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoSaidaNFEBonificacao' component={NoteExitNFELocalBonus} nfe={'S'} tipo_emissao={'3'}/>

                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoSaidaNFEAvariaPerca/AdicionarProduto/:editProductMobile?' component={NoteExitNFELocalBreakdownMiss} nfe={'S'} tipo_emissao={'4'}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoSaidaNFEAvariaPerca' component={NoteExitNFELocalBreakdownMiss} nfe={'S'} tipo_emissao={'4'}/>

                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoSaidaNFEOutros/AdicionarProduto/:editProductMobile?' component={NoteExitNFELocalOthers} nfe={'S'} tipo_emissao={'5'}/>
                                    <RoutePrivate socket={socket} exact path='/AdicionarProdutoSaidaNFEOutros' component={NoteExitNFELocalOthers} nfe={'S'} tipo_emissao={'5'}/>

                                
                                    <RoutePrivate socket={socket} exact path='/listarFormaPagamento' component={FormOfPayment} />
                                    <RoutePrivate socket={socket} exact path='/FormaPagamento/:idPagamento?' component={FormOfPayment} />
                                    <RoutePrivate socket={socket} exact path='/listarDespesa' component={Expense} />
                                    <RoutePrivate socket={socket} exact path='/Despesa/:idDespesa?' component={Expense} />
                                    <RoutePrivate socket={socket} exact path='/listarReceita' component={Revenue} />
                                    <RoutePrivate socket={socket} exact path='/Receita/:idReceita?' component={Revenue} />
                                    <RoutePrivate socket={socket} exact path='/listarContaCorrente' component={CurrentAccount} />
                                    <RoutePrivate socket={socket} exact path='/ContaCorrente/:idContaCorrente?' component={CurrentAccount} />
                                    <RoutePrivate socket={socket} exact path='/listarContasPagar/:pageNumber?' component={BillsToPay} />
                                    <RoutePrivate socket={socket} exact path='/ContasPagar/:idContasPagar?/:pageNumber?' component={BillsToPay} />
                                    <RoutePrivate socket={socket} exact path='/listarContasPagas/:pageNumber?' component={BillsToPay} />
                                    <RoutePrivate socket={socket} exact path='/ContasPagas/:idContasPagar?/:pageNumber?' component={BillsToPay} />
                                    <RoutePrivate socket={socket} exact path='/listarContasCanceladas/:pageNumber?' component={BillsToPay} />
                                    <RoutePrivate socket={socket} exact path='/ContasCanceladas/:idContasPagar?/:pageNumber?' component={BillsToPay} />
                                    <RoutePrivate socket={socket} exact path='/listarContasReceber/:pageNumber?' component={BillsToReceive} />
                                    <RoutePrivate socket={socket} exact path='/ContasReceber/:idContasReceber?/:pageNumber?' component={BillsToReceive} />
                                    <RoutePrivate socket={socket} exact path='/listarContasRecebidas/:pageNumber?' component={BillsToReceive} />
                                    <RoutePrivate socket={socket} exact path='/ContasRecebidas/:idContasReceber?/:pageNumber?' component={BillsToReceive} />
                                    <RoutePrivate socket={socket} exact path='/listarContasReceberCanceladas/:pageNumber?' component={BillsToReceive} />
                                    <RoutePrivate socket={socket} exact path='/ContasReceberCanceladas/:idContasReceber?/:pageNumber?' component={BillsToReceive} />
                                    <RoutePrivate socket={socket} exact path='/listarNotasEntrada' component={ConsultNoteEntry} />
                                    <RoutePrivate socket={socket} exact path='/entrada/:idEntrada' component={ConsultNoteEntry} />
                                    <RoutePrivate socket={socket} exact path='/Fabricante/:idFabricante?' component={Manufactory} />
                                    <RoutePrivate socket={socket} exact path='/listarFabricante' component={Manufactory} />
                                    <RoutePrivate socket={socket} exact path='/Secao/:idSecao?' component={Section} />
                                    <RoutePrivate socket={socket} exact path='/listarSecao' component={Section} />

                                    <RoutePrivate exact path='/Ncm/:iDNcm?' component={Ncm} />
                                    <RoutePrivate exact path='/listarNcm' component={Ncm} />
                                    {/* <RoutePrivate exact path='/listarCfop' component={Cfop} />
                                    <RoutePrivate exact path='/Cfop/:idCfop?' component={Cfop} /> */}
                                </Switch> 
                            </SocketDeliverProvider>
                        </RulesPermissionUserProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </Suspense>
        </Router>
    )    
}

const AtendentesRoutes = ()=> {
    const { path } = useRouteMatch();
    return(
        <Switch>
            <Route exact path={path} component={ListaAtendentesPage}/>
        </Switch>
    )
}

const ProdutosRoutes = ()=> {
    const { path } = useRouteMatch();
    return(
        <Switch>
            <Route exact path={path} component={ListarProduto}/>
        </Switch>
    )
}

const UsuarioRoutes = ()=> {
    const { path } = useRouteMatch();
    return(
        <Switch>
            <Route exact path={path}  component={ListarUsuario}/>
        </Switch>
    )
}
