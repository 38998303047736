import styled from "styled-components";

export const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 0px 24px;
    gap: ${props => props.theme.gaps.small};
    margin-top: ${props => props.theme.margins.large};
    width: ${props => props.openSidebar ? '270px' : '80px'};
    min-width: ${props => props.openSidebar ? '270px' : '80px'};
    background: ${props => props.theme.backgrounds.white} ;
    border-right: ${props => props.theme.border.very_small_gray5};
    overflow:hidden;
    height: 100%;
    z-index: 2002;
    padding-bottom: 100px;
`;

export const BoxMainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: ${props => props.theme.gaps.small};
    width: 100%;
    height: 100%;
    order: 0;
    flex-grow: 0;
    overflow:hidden auto;

    scrollbar-width: none;

    ::-webkit-scrollbar {
        width: 0px;          
        border-radius: 100px;

    }

    ::-webkit-scrollbar-thumb{
        width: 0px;  
        border-radius: 100px;
    }
`;


export const BoxContainerInfoSideBar =  styled.div`
    display: flex;
    align-items: flex-start;
    padding: 10px 0px 10px 24px;
    gap: ${props => props.theme.gaps.small};
    width: 100%;
    height: 35px;
    flex: none;
    order: 0;
    flex-grow: 0;

    p{
        width: 151px;
        height: 15px;
        font-size: ${props => props.theme.font_size.very_small};
        line-height: ${props => props.theme.lines_height.small};
        color: ${props => props.theme.colors.gray2};
    }


`;

export const Span = styled.span `
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
`;

export const Ul = styled.ul`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    flex: 0 0 auto;
`

export const TooltipBox = styled.div`
    position: fixed;
    left: 0px;
    visibility: hidden;
    color: transparent;
    background-color: transparent;
    min-width: 300px;
    padding: 5px 5px;
    z-index: 3000;
    border-radius: 4px;
    transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;

    /* &:before {
        content: "";
        width: 0;
        height: 0;
        left: 40px;
        top: -10px;
        position: absolute;
        border: 10px solid transparent;
        transform: rotate(135deg);
        transition: border 0.3s ease-in-out;
    } */
`;

export const WrapperIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: ${props => props.position ?? ''};
    left: ${props => props.left ?? ''};
`;

export const Li = styled.li`
    display: flex;
    align-items: center;
    padding: ${props => props.padding ?? '10px 10px 10px 24px'};
    gap: 10px;
    width: 100%;
    height: 44px;
    flex: 0 0 auto;
    cursor: pointer;
    :hover{
        background-color: #E1E4E8;
        border-left: 5px solid ${props => props.theme.backgrounds.blue4};

    }

`;


export const Teste = styled.div`
    gap: 10px;
    width: 100%;
    height: 44px;
    cursor: pointer;
    position: relative;
    & ${Li}:hover + ${TooltipBox} {
        visibility: visible;
        color: black;
        z-index: 5;
        background: #E1E4E8;
        width: 100%;
        padding: 8px;
        position: fixed;
        /* top:450px; */
        top:${props => `${props.styles.top}px`};
        &:before {
            border-color: transparent transparent ${props => props.theme.backgrounds.red2} ${props => props.theme.backgrounds.red2}
        }
    }

`;



