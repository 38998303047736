
import { useRef } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { RulesPermissionUserContext } from '../../contexts/RulesPermissionUser'
import { handleClickCloseModalGeneric, verifyMouse } from '../../functionsGlobal'
import * as StylesGlobal from '../../shared/GlobalStyles/styles'
import { theme } from '../../shared/GlobalStyles/theme'
import { ButtonAction } from '../ButtonsActions/ButtonAction'

export const AccessDenied = () => {
    const {closeModalAcessDenied} = useContext(RulesPermissionUserContext);
    const refModal = useRef()

    useEffect(() => {
        refModal.current.focus();
    }, [])

    const keyPress = (e) => { if(e.key === 'Escape') closeModalAcessDenied();}

    return (
        <StylesGlobal.ContainerGenericModal id={'modal-generic'}
            tabIndex={1}
            ref={refModal}
            onMouseDown={(e) => verifyMouse(e, handleClickCloseModalGeneric, closeModalAcessDenied)}
            onMouseUp={(e) => verifyMouse(e, handleClickCloseModalGeneric, closeModalAcessDenied)}            
            onKeyUp={keyPress}
        >
            <StylesGlobal.WrapperGenericModal width={'40%'} height={'40%'}>
                <StylesGlobal.ContainerHeader>
                    <StylesGlobal.HeaderModal>
                        <h1>Acesso negado</h1>
                        <StylesGlobal.IconCloseModal onClick={closeModalAcessDenied}>
                            <StylesGlobal.IconClose  stroke={theme.backgrounds.red2} />
                        </StylesGlobal.IconCloseModal>
                    </StylesGlobal.HeaderModal>
                </StylesGlobal.ContainerHeader>

                <StylesGlobal.WrapperBodyModalGeneric>
                    <StylesGlobal.WrapperModalGeneriCenter>
                        <StylesGlobal.IconAccessDenied/>
                    </StylesGlobal.WrapperModalGeneriCenter>
                </StylesGlobal.WrapperBodyModalGeneric>

                <StylesGlobal.ContainerFooter>  
                    <StylesGlobal.Footer>
                        <ButtonAction
                            width={'132px'}
                            height={'48px'} 
                            fontWeight={'600'}
                            variant={1}
                            onClick={closeModalAcessDenied}        
                        >
                            Cancelar
                        </ButtonAction>
                    </StylesGlobal.Footer>

                </StylesGlobal.ContainerFooter>
            </StylesGlobal.WrapperGenericModal>
        </StylesGlobal.ContainerGenericModal>
    )

}